<template>
  <div class="hp-management-view">
    <ContentBlockItemFilter />
    <Timeline
      v-for="contentBlock in contentBlocks"
      :key="contentBlock.id"
      :content-block="contentBlock"
      ref="timelineComponents"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContentBlockItemFilter from '@/components/contentBlockItem/ContentBlockItemFilter'
import Timeline from '@/components/contentBlockItem/timeline/Timeline'
export default {
  name: 'HpManagementView',
  components: {
    ContentBlockItemFilter,
    Timeline
  },
  data () {
    return {
      hpTimeStatusInterval: 0,
      timelineConfig: {
        positions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        times: []
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    contentBlocks () {
      const contentBlocksList = this.$store.getters['contentBlock/list']
      return contentBlocksList.filter(contentBlock => contentBlock.type === 'article')
    },
    filter () {
      return this.$store.getters['contentBlock/hpManagementFilter']
    }
  },
  methods: {
    init () {
      this.getContentBlocks()
    },
    getContentBlocks () {
      this.$store.commit('contentBlock/setLimit', 50)
      this.$store.commit('contentBlock/setPage', 1)
      this.$store.commit('contentBlock/setHpManagementFilter', this.filter)
      this.$store.dispatch('contentBlock/fetchForHpManagement')
    },
    updateTimelines () {
      const timelineComponents = this.$refs.timelineComponents
      timelineComponents.forEach(timeline => timeline.updateData())
    }
  },
  created () {
    this.init()
  },
  mounted () {
    clearInterval(this.hpTimeStatusInterval)
    this.hpTimeStatusInterval = setInterval(() => {
      this.updateTimelines()
    }, 10000)
  },
  beforeDestroy () {
    clearInterval(this.hpTimeStatusInterval)
  }
}
</script>

<style scoped lang="scss">
  .hp-management-view {
    @media screen and (min-width: 0px) and (max-width: 767px) {
      margin-top: 1rem;
    }
  }
</style>
